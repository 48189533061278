import React from "react";
import {Col} from "react-bootstrap";
import {window} from "window-or-global";

const AccountDetails = (props) => {
  const {user} = props;
  const [supportID] = (window.atob(user.id) || "").split(":");
  const {building} = user;

  let currentProduct, activeRenewal;
  if (user.activeRenewal !== null && user.activePlan !== null) {
    currentProduct = (user?.activeRenewal?.products?.edges || [])[0]?.node || {};
  }

  return (
    <Col xs={12} xl={4} className="mb-3">
      <div className="panel-container h-100">
        <div className="panel-header">{"Account Details"}</div>
        <div className="panel-body h-100">
          <div className="user-details border-bottom-0">
            <div className="user-label">{"Building"}</div>
            <div className="user-info">
              {building?.formattedAddress}
            </div>
            <div className="user-label">{"Status"}</div>
            <div className="user-info">{user?.disabled ? "Inactive" : "Active"}</div>

            <div className="user-label">{"Support ID"}</div>
            <div className="user-info">{`${supportID}`}</div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AccountDetails;
