const ORDER_STATUS = [
  {value: 1, label: "COMPLETED", displayLabel: "Completed Account"},
  {value: 2, label: "CANCELLED", displayLabel: "Cancelled Account"},
  {value: 3, label: "CANCELLING", displayLabel: "Cancelling Account"},
  {value: 4, label: "ACTIVE", displayLabel: "Active Account"},
  {value: 5, label: "SCHEDULED", displayLabel: "Scheduled"},
  {value: 6, label: "PROVISIONING", displayLabel: "Provisioning"},
];

const PLAN_INFORMATION_STATUSES = [2, 3, 6];
const NON_INTERNET_PRODUCT_TYPES_TO_SHOW = ["staticip", "voip"];

export {
  ORDER_STATUS,
  PLAN_INFORMATION_STATUSES,
  NON_INTERNET_PRODUCT_TYPES_TO_SHOW,
};
