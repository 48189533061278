import React, {useRef} from "react";
import {Row, Col, Container, Button, Carousel} from "react-bootstrap";
import Formsy from "formsy-react";
import {useAsyncSetState} from "use-async-setstate";
import {useQuery, useMutation} from "@apollo/client";
import {getOperationName} from "apollo-utilities";
import moment from "moment-mini";

import {catchErrorMessage} from "../utils/error-message";
import Modal from "../bootstrap/modal";
import {getProductsQuery, getProductsResult} from "../logic/products";
import {
  getCurrentUserQuery,
  changePlanMutation,
  changePlanResult,
} from "../logic/user";
import Loader from "../bootstrap/loader";
import Main from "../main";
import Layout from "../section/layout";
import Seo from "../section/seo";
import {console} from "window-or-global";
import Addons from "../../controls/register/addons-display";
import Input from "../bootstrap/input";
import { NON_INTERNET_PRODUCT_TYPES_TO_SHOW } from "../../utils/constant";

const Index = (props) => {
  return (
    <Main>
      <Layout>
        <Seo title="Evergy - change plan" />
        <DataLayer {...props} />
      </Layout>
    </Main>
  );
};

const DataLayer = (props) => {
  const {onClose, user} = props;
  const buildingCode = user?.building?.code;
  const [voucherCode, setVoucherCode] = useAsyncSetState(null);
  const getProducts = useQuery(getProductsQuery, {
    variables: {
      buildingCode,
      showHidden: true,
      voucherCode,
    },
  });

  if (getProducts?.loading) {
    return <ModalLoader onClose={onClose} />;
  }

  const products = getProductsResult(getProducts);

  return (
    <ChangePlan
      buildingCode={buildingCode}
      products={products}
      voucherCode={voucherCode}
      setVoucherCode={(data) => setVoucherCode(data)}
      {...props}
    />
  );
};


const ChangePlan = (props) => {
  const {onClose, selectedOrder, products, voucherCode, setVoucherCode, buildingCode, user} = props;
  const [state, setState] = useAsyncSetState({
    success: false,
    confirm: false,
    processing: false,
    error: null,
    data: null,
  });

  const noAccount = (user?.accounts?.edges || []).length === 0;
  const [voucherInput, setVoucherInput] = useAsyncSetState(voucherCode || "");
  const [successMessage, setSuccessMessage] = useAsyncSetState("");
  const [selectedProduct, setProduct] = useAsyncSetState(null);
  const [changePlan] = useMutation(changePlanMutation);
  const plans = products.filter((value) => value.type === "internet" && !value.hidden);
  const orderProducts = (selectedOrder?.orderItems?.edges || []).map(
    (orderItem) => orderItem?.node?.product
  );

  const internetPlan = (orderProducts).find((oi) => oi?.type === "internet");

  const genericAddons = orderProducts.filter(
    (p) => {
      return NON_INTERNET_PRODUCT_TYPES_TO_SHOW.indexOf(p.type) > -1 && products.filter(a=>a.id === p.id).length > 0;
    }
  );
  const hardwareAddons = orderProducts.filter((p) => p.type === "hardware");
  const activeProduct = orderProducts.find(
    (value) => value.type === "internet"
  );
  const defaultProductIndex = plans.findIndex(
    (p) => p.id === activeProduct?.id
  );
  const [index, setIndex] = useAsyncSetState(defaultProductIndex > -1 ? defaultProductIndex : 0);
  const [addons, setAddons] = useAsyncSetState(genericAddons);
  const [selectedProductIds, setSelectedProductIds] = useAsyncSetState(internetPlan?.id ? [internetPlan?.id] : []);
  const formRef = useRef(null);

  const handleOnAddProduct = (product) => {
    let newAddons = [...addons, product];
    newAddons = newAddons
      .filter((value, index) => newAddons.findIndex((na) => na.id === value.id) === index);
    // .filter((value) => value.type !== "hardware");

    return setAddons(newAddons);
  };

  const handleOnRemoveProduct = (product) => {
    return setAddons(addons.filter((ao) => ao.id !== product.id));
  }

  const handleSubmit = () => {
    return setState((prevState) => ({
      ...prevState,
      confirm: true,
      error: null,
    }));
  };

  const handlePurchase = async ({schedule = false}) => {
    try {
      await setState((prevState) => ({
        ...prevState,
        confirm: false,
        processing: true,
      }));

      const genericAddonProducts = (addons || []).map((ao) => ao.id);
      //TODO - Detect if no changes and skip updateOrder

      const changePlanResponse = await changePlan({
        variables: {
          orderId: selectedOrder?.id,
          productIds: [selectedProduct.id].concat(genericAddonProducts || []),
          voucherCode,
          schedule,
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });
      let order = changePlanResult(changePlanResponse);
      if (!order?.id || changePlanResponse.error) {
        console.log("err update failed", order, changePlanResponse);
        throw "Error - unable to continue";
      }
      if (order?.id) {
        const total = [selectedProduct]
          .concat(addons.filter(ao => !ao.once || (ao.once && !orderProducts.find(op => op.id === ao.id))) || [])
          .reduce((prev, value) => prev + Number(value?.value || 0), 0);

        if (schedule) {
          await setSuccessMessage(`Your order has been updated and you will be charged '$${total}' on the '${moment(order?.renewsAt).format("DD MMM YYYY")}'`);
        } else {
          await setSuccessMessage(`Your account has been successfully charged '$${total}' and your account will now renew on '${moment(order?.renewsAt).format("DD MMM YYYY")}'`);
        }
        return setState((prevState) => ({
          ...prevState,
          success: true,
          processing: false,
          confirm: false,
        }));
      }

      return setState((prevState) => ({
        ...prevState,
        processing: false,
        confirm: false,
        success: false,
        error: catchErrorMessage(changePlanResponse?.errors || "An error has occurred."),
      }));
    } catch (err) {
      console.error(err);
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error:
          err.message.replace(/(GraphQL error:)/gi, "") ||
          "An error has occurred.",
      }));
    }
  };

  return (
    <Modal
      title={"Change Plan"}
      show
      onClose={onClose}
      footer={(() => {
        if (!state.processing && !state.success && !state.confirm) {
          return (
            <Row>
              <Col xs="auto" className="ml-auto">
                <Button
                  variant="light"
                  onClick={async () => {
                    return onClose && onClose();
                  }}>
                  {"Cancel"}
                </Button>
              </Col>
              <Col xs="auto">
                <div>
                  <Button
                    variant="orange"
                    className="vw-button orange"
                    type="submit"
                    disabled={noAccount}
                    onClick={async() => {
                      await setProduct(plans[index]);
                      return handleSubmit();
                    }}>
                    {"Confirm"}
                  </Button>
                </div>
              </Col>
            </Row>
          );
        }
        return undefined;
      })()}
    >
      <Container>
        {noAccount && (
          <Row>
            <Col>
              <div className="alert alert-danger">
                {"You need to update your credit card information before you can purchase product"}
              </div>
            </Col>
          </Row>
        )}
        {state.error && (
          <Row>
            <Col>
              <div className="alert alert-danger">
                {state.error}
              </div>
            </Col>
          </Row>
        )}
        {(() => {
          if (state.processing) {
            return (
              <div>
                <Loader />
                {"Processing"}
              </div>
            );
          }

          if (state.success && successMessage) {
            return (
              <div>
                <span>
                  {successMessage}
                </span>
                <Row className="vw-portal-button-popup-row justify-content-end">
                  <Col xs="auto">
                    <Button variant="darkblue" onClick={async() => {
                      await setSuccessMessage("");
                      return onClose();
                    }}>
                      {"Close"}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }

          if (state.confirm) {
            const addOnsTotal = (addons.filter(ao => !ao.once || (ao.once && !orderProducts.find(op => op.id === ao.id))) || [])
              .reduce(
                (prev, value) => Number(value.value) + Number(prev),
                0
              );
            const monthly = (selectedProduct.value || 0) + (addOnsTotal || 0);
            return (
              <div>
                <div className="mb-3">
                  {"If you purchase now you will be restarting your monthly period and you will be charged a total of "}
                  <span className="bold">{`$${monthly} `}</span> {/*It will include newly added hardware (if any) in the first month*/}
                  {"to continue."}
                </div>
                <div>
                  {"Alternatively you can schedule your change and will be charged "}
                  <span className="bold">{`$${monthly} `}</span>
                  {" at the end of the month."}
                </div>
                <Row className="vw-portal-button-popup-row">
                  <Col xs="auto">
                    <Button
                      type="button"
                      variant="light mr-auto"
                      disabled={state.processing}
                      onClick={async () => setState({...state, confirm: false})}>
                      <i className="far fa-arrow-left mr-3" />
                      {"Go Back"}
                    </Button>
                  </Col>
                  <Col xs="auto ml-auto">
                    {selectedOrder?.status !== 2 && (
                      <Button
                        type="button"
                        variant="darkblue"
                        disabled={state.processing}
                        onClick={async () => handlePurchase({schedule: true})}>
                        <i className="far fa-calendar mr-3" />
                        {"Scheduled Purchase"}
                      </Button>
                    )}
                  </Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      variant="darkblue"
                      disabled={state.processing}
                      onClick={async () => handlePurchase({schedule: false})}>
                      <i className="far fa-check mr-3" />
                      {"Purchase Now"}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }

          return (
            <Formsy ref={formRef} onValidSubmit={handleSubmit}>
              <Row className="field-row mb-2 cursor-pointer">
                <Col>
                  <Input name="voucherCode" placeholder="Voucher Code" value={voucherInput} onChange={(e) => setVoucherInput(e?.target?.value || "")} />
                </Col>
                <Col xs="auto">
                  <Button variant="orange orange vw-button" type="button" disabled={!voucherInput} onClick={(e) => {
                    e.preventDefault();
                    return setVoucherCode(voucherInput);
                  }}>
                    {"Apply"}
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button variant="darkblue vw-button" type="button" disabled={!voucherCode} onClick={async (e) => {
                    e.preventDefault();
                    await setVoucherInput("");
                    return setVoucherCode("");
                  }}>
                    {"Clear"}
                  </Button>
                </Col>
              </Row>
              <Row className="field-row mb-2">
                <Col>
                  <div className="plan-content-inner">
                    <Container
                      fluid
                      className="product-group">
                      <Carousel
                        // key={voucherCode || 0}
                        activeIndex={index}
                        className="w-100"
                        interval={null}
                        nextIcon={
                          <i
                            className="fas fa-angle-right carousel-control"
                            onClick={async () => {
                              const newIndex = index < plans.length - 1 ? index + 1 : 0;
                              await setSelectedProductIds(plans[newIndex]?.id ? [plans[newIndex]?.id] : []);

                              return setIndex(newIndex);
                            }}
                          />
                        }
                        prevIcon={(
                          <i
                            className="fas fa-angle-left carousel-control"
                            onClick={async () => {
                              const newIndex = index > 0 ? index - 1 : (plans || []).length - 1;
                              await setSelectedProductIds(plans[newIndex]?.id ? [plans[newIndex]?.id] : []);
                              return setIndex(newIndex);
                              // const product = plans[newIndex];
                              // const selectevents = product?.selectEventsActivate;
                              // let newAddons = [];

                              // (selectevents || []).forEach((event) => {
                              //   const eventProduct = products.find((p) => (p.name || "").toLowerCase() === (event || "").toLowerCase());
                              //   if (eventProduct) {
                              //     if (eventProduct?.type !== "hardware") {
                              //       newAddons.push(eventProduct);
                              //     }
                              //   }
                              // });
                              // return setAddons(newAddons);
                            }}
                          />
                        )}>
                        {plans.map((product) => {
                          return (
                            <Carousel.Item key={product?.id}>
                              <div className="cursor-pointer a-product">
                                <div className="product-container flex-nowrap col col-6 mx-auto border">
                                  <div className="product-title">
                                    {product.name}
                                  </div>
                                  <div className="product-image">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="100"
                                      height="100"
                                      viewBox="0 0 18 18">
                                      <path
                                        fill="#0065a0"
                                        fillOpacity=".3"
                                        d="M9.01 15.99l8.8-10.96C17.47 4.77 14.08 2 9 2S.53 4.77.19 5.03l8.8 10.96h.02z"
                                      />
                                    </svg>
                                  </div>
                                  <div className="price-speed">
                                    <div
                                      className="price"
                                      style={{
                                        textDecoration: product.originalValue
                                          ? "line-through"
                                          : undefined,
                                      }}>
                                      {`$${
                                        product.originalValue
                                          ? product.originalValue
                                          : product.value
                                      }`}
                                    </div>
                                    <div className="per-month">
                                      {"Per Month"}
                                    </div>
                                    {(product || {}).voucherName && (
                                      <div className="product-voucher">
                                        {`$${product.value} ${product.voucherName}`}
                                      </div>
                                    )}
                                    <div className="speed">
                                      {product.description}
                                    </div>
                                  </div>
                                  <div className="details">
                                    <div>{"Unlimited Data"}</div>
                                  </div>
                                  <div className="sub-details">
                                    <div>{"Typical Evening Speed"}</div>
                                    <div>
                                      {`${getPlanSpeedLabel(product.name)} Mbps`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </Container>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Addons
                    buildingCode={buildingCode}
                    disableHardWare
                    onAddProduct={handleOnAddProduct}
                    onRemoveProduct={handleOnRemoveProduct}
                    addons={addons}
                    selectedProductIds={selectedProductIds}
                    voucherCode={voucherCode}
                  />
                </Col>
              </Row>
            </Formsy>
          );
        })()}
      </Container>
    </Modal>
  );
};

const ModalLoader = ({onClose}) => {
  return (
    <Modal
      title={"Change Plan"}
      show
      onClose={onClose}>
      <div className="rotating-loader mx-auto my-3" />
    </Modal>
  );
};

const getPlanSpeedLabel = (product) => {
  switch (product) {
    case "50/20 Mbps":
      return "47.3";
    case "100/20 Mbps":
    case "100/40 Mbps":
      return "96.4";
    case "250/25 Mbps":
    case "250/100 Mbps":
      return "241.0";
    case "1000/50 Mbps":
    case "1000/400 Mbps":
      return "250+";
  }

  return "47.3";
};

export {
  getPlanSpeedLabel,
};
export default Index;
