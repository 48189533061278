import React, {Fragment} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import moment from "moment-mini";

import ChangePlan from "../../components/portal/change-plan";
import CancelPlan from "../../components/portal/cancel-plan";
import UpdateAccount from "../../components/portal/update-account";
import {NON_INTERNET_PRODUCT_TYPES_TO_SHOW, ORDER_STATUS, PLAN_INFORMATION_STATUSES} from "../../utils/constants";

const PlanInformation = (props) => {
  const {user} = props;
  const [showChangePlan, setShowChangePlan] = useAsyncSetState(false);
  const [showUpdateCC, setShowUpdateCC] = useAsyncSetState(null);
  const [selectedCancelOrder, setCancelOrder] = useAsyncSetState(null);
  const {activeOrders = [], activePlan} = user;

  return (
    <Fragment>
      {!!showChangePlan && (
        <ChangePlan
          user={user}
          selectedOrder={showChangePlan}
          show
          onClose={() => setShowChangePlan(null)}
        />
      )}
      {!!selectedCancelOrder && (
        <CancelPlan
          user={user}
          selectedCancelOrder={selectedCancelOrder}
          show
          onClose={async () => setCancelOrder(null)}
        />
      )}
      {!!showUpdateCC && (
        <UpdateAccount
          user={user}
          show
          onClose={() => setShowUpdateCC(false)}
        />
      )}
      <Col md={12} lg={8} className="mb-3">
        <div className="panel-container h-100">
          <div className="panel-header">{"Your Plan Information"}</div>
          <div className="panel-body h-100">
            <div className="plan">
              <Orders
                activePlan={activePlan}
                activeOrders={activeOrders.filter(ao => ao.orderItems?.edges?.length > 0)} // Filter plans without order item
                user={user}
                selectedCancelOrder={selectedCancelOrder}
                setCancelOrder={async (data) => setCancelOrder(data)}
                setShowChangePlan={async (data) => setShowChangePlan(data)}
              />

              <Row style={{marginTop: "20px"}}>
                <Col xs={8}/>
                <Col xs={12} md={4} lg={4} className="text-center">
                  <a
                    onClick={async () => setShowUpdateCC(true)}
                    className="vw-button btn btn-darkblue d-block ml-auto px-4 py-2"
                  >
                    {"Update Credit card"}
                  </a>
                </Col>
              </Row>
              {activeOrders.length === 0 && <Row style={{marginTop: "20px"}}>
                <Col md={8} xs={7} className="plan-information"/>
                <Col md={4} xs={5} className="text-center plan-section">
                  <a
                    className="vw-button btn btn-darkblue purchase-btn d-block ml-auto"
                  >
                    {"Purchase Plan"}
                  </a>
                </Col>
              </Row>}
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

const Orders = ({activeOrders = [], user, setCancelOrder, setShowChangePlan}) => {
  return activeOrders.map((activeOrder) => {
    const internetOrderItem = (activeOrder?.orderItems?.edges.find(a=>a?.node?.product?.type === "internet"))?.node;
    const nonInternetOrderItems = activeOrder?.orderItems?.edges
      .filter(oi => oi.node?.product?.type !== "internet")
      .filter(oi => !oi.node?.product?.once) // filters items that are paid once
      .filter(oi => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(oi.node?.product?.type))
      .map(foi => foi.node);

    return (
      <Row key={activeOrder.id} style={{borderBottom: "1px solid #eee", paddingBottom: 20, marginBottom: 20}}>
        <Col md={8} sm={12}>
          <InternetOrder
            internetOrderItem={internetOrderItem}
          />
          <OrderItem
            activeOrder={activeOrder}
            user={user}
            orderItems={nonInternetOrderItems}
          />
          <div className="plan-payment vw-portal-account-automatic">
            <Container>
              <Row>
                <Col xs="auto" className="pl-0">
                  <i className="fas fa-credit-card" style={{fontSize: 40}} />
                </Col>
                <Col>
                  <Row className="renewal-status">
                    {ORDER_STATUS.find(os => (os.value === activeOrder?.status && PLAN_INFORMATION_STATUSES.includes(os.value)))?.label}
                  </Row>
                  <Row className="plan-desc" style={{fontSize: 10}}>
                    {internetOrderItem && getPlanDescription(
                      user,
                      (activeOrder?.orderItems?.edges || []).find(({node: orderItem}) => !orderItem?.product?.once && orderItem?.product?.type === "internet")?.node?.product || {value: 0},
                      activeOrder?.status,
                      (activeOrder?.orderItems?.edges || []).reduce((prev, value) => {
                        if (!value?.node?.product?.once) {
                          return Number(value?.node?.product?.value || 0) + prev;
                        }

                        return prev;
                      }, 0),
                      internetOrderItem,
                      activeOrder
                    )}
                    {!internetOrderItem && <Fragment>
                      <h6 className="font-weight-bold">{"Automatic Payment"}</h6>
                      <div>
                        {`You will be charged $${nonInternetOrderItems.reduce((prev, curr) => prev + parseFloat(curr?.product?.value), 0)} 
                          for your selected plan by automatic fund transfer on ${moment(activeOrder.renewsAt).format("LL")}`}
                      </div>
                    </Fragment>}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col md={4} sm={12}>
          {internetOrderItem && activeOrder?.status !== 6 && (
            <a
              onClick={async () => setShowChangePlan(activeOrder)}
              className="vw-button btn btn-darkblue mb-2 d-block ml-auto px-4 py-2"
            >
              {"Change Plan"}
            </a>
          )}
          {activeOrder.status >= 4 && activeOrder.status !== 6 && (
            <a
              onClick={async () => setCancelOrder(activeOrder)}
              className="vw-button btn btn-darkblue w-100"
            >
              {"Cancel Order"}
            </a>
          )}
        </Col>
      </Row>
    );
  });
};

const InternetOrder = ({internetOrderItem}) => {
  if (!internetOrderItem) {
    return <Fragment/>;
  }
  const product = internetOrderItem?.product;
  return (
    <div className="plan-title" key={internetOrderItem.id}>
      {`${product?.name || "No Active Plan"}${
        product?.rateProfile?.name ? " - " : ""
      }${product?.rateProfile?.name || ""}`}
    </div>
  );
};


const OrderItem = ({orderItems = [], activeOrder, user}) => {
  return (
    <section className="order-items_section">
      {orderItems.map(orderItem => {
        const product = orderItem?.product;
        return (
          <div className="order-item-title" key={orderItem.id}>
            {/* {JSON.stringify(product)} */}
            {`${product?.name || "No Active Plan"}${
              product?.rateProfile?.name ? " - " : ""
            }${product?.rateProfile?.name || ""}`}
          </div>
        );
      })}
    </section>
  );
};

const getPlanDescription = (user, product, status, total, internetOrderItem, activeOrder) => {
  const planEnd = moment(activeOrder?.renewsAt);

  if (user?.scheduledRenewal) {
    let scheduledProduct = (user?.scheduledRenewal.products || [])[0] || {};

    return `Your plan will change to ${scheduledProduct.description} for $${
      scheduledProduct.value
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
  if (internetOrderItem?.product.id !== product.id) {
    const planEnd = moment(internetOrderItem?.end);
    return `Your plan will change to ${product.description} for $${
      total
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }

  switch (status) {
    case undefined:
      return "You do not have an active plan.";
    case 1:
    case 2:
      return "Your plan is inactive";
    case 3:
      return `Your plan will be disabled on the ${planEnd.format(
        "Do of MMMM YYYY"
      )}`;
    case 6:
      return product.description;
    default:
      return (
        <Fragment>
          <h6 className="font-weight-bold">{"Automatic Payment"}</h6>
          {
            `Your next automatic payment for plan ${internetOrderItem.product.description} will be 
            $${total || 0} on ${planEnd.format("Do of MMMM YYYY")}.`
          }
        </Fragment>
      );
  }
};

export default PlanInformation;
